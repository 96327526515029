@import 'styles/config';

.container {
  background-color: var(--greyscale-cool-cool-grey-3, #F5F5F5);
}

.carouselContainer {
  @include grid;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: clamp(64px, 10vw, 128px);
}

.carouselHeading {
  @include grid-item(10, 1);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;

  &__text {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 40px;

    color: $color-secondary;
  }

  &__icon {
    width: 16px;
    @media (min-width: $min-mobile) {
      width: 24px;
    }
    color: $color-secondary;
  }

  &__button {
    padding: 16px;

    border-radius: 56px;

    background-color: white;
  }

}

.carousel{
  @include grid-item(12, 2);
  display: flex;
  flex-shrink: 0;

  position: relative;

  padding-top: 64px;
  padding-bottom: 128px;

  width: 100%;
  overflow-x: scroll;

  &__inner {
    display: flex;
    flex-direction: row;
    gap: 32px;

    padding-left: 8.2vw;
    @media (min-width: 1860px) {
      padding-left: 12vw;    
    }

    padding-right: 40px;
    
  }
}
.carousel::-webkit-scrollbar {
  display: none;
}

.carousel:not(.dragging):hover {
  cursor: grab;
}

.dragging {
  cursor: grabbing;
  user-select: none;
}

.disabled{
  opacity: 0.25;
}