@import 'styles/config';

.carouselCard {
  position: relative;

  width: clamp(250px, 40vw, 392px);

  &__imageWrap {
    position: relative;

    overflow: hidden;

    border-radius: 8px;

    &::before {
      @include aspect-ratio(392, 412);

      content: '';

    }
  }

  &__image {
    @include fit;
    @include media-position;

    transform: scale(1);

    transition: transform $transition-duration $ease-out-cubic;
  }

  &:focus-within &__image,
  &:hover &__image {
    transform: scale(1.05);

    transition-duration: $animation-duration;
  }

  &__content {
    padding-top: var(--gutter);
  }

  &__title {
    @include h4;
    padding-bottom: scale-to-em(16px, $h3-size);
    padding-top: 16px;

    color: $color-secondary;
  }

  &__link {
    text-decoration: none;
  }

  &__floating_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    transition: transform $transition-duration $ease-out-cubic;
    &__title {
      @include responsive-font(16px, 36px);
      font-weight: 400;

      color: $color-font-contrast;
    }

    &__arrow {
      width: 16px;

      @media (min-width: $min-desktop) {
        width: 32px;      }

      color: $color-font-contrast;

      transform: rotate(180deg);
    }
  }

  &:focus-within &__floating_container,
  &:hover &__floating_container {
    transform: scale(1.05);
    
    transition-duration: $animation-duration;
  }

}
