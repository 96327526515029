@import 'styles/config';

.listCard {
  position: relative;

  width: 100%;

  &.offset {
    margin-top: 60px;
  }

  &::after {
    // green box hover effect
    @include aspect-ratio(860, 631);

    content: '';

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;

    border-radius: 8px;

    opacity: 0;

    background-color: $color-primary;

    transition: all $animation-duration $ease-out-cubic;
    transition-property: opacity, transform;
  }

  &.three::after {
    @include aspect-ratio(563, 482);
  }

  &.four::after {
    @include aspect-ratio(
      1,
      1
    ); // NB. requested by Orkusalan as images they use are square
  }

  &__imageWrap {
    position: relative;

    overflow: hidden;

    border-radius: 8px;

    &::before {
      @include aspect-ratio(860, 631);

      content: '';

      .three & {
        @include aspect-ratio(563, 482);
      }

      .four & {
        @include aspect-ratio(1, 1);
      }
    }
  }

  &__image {
    @include fit;
    @include media-position;
  }

  &:focus-within::after,
  &:hover::after {
    opacity: 1;

    transform: translate3d(10px, 10px, 0);
  }

  &__content {
    padding-top: var(--gutter);
  }

  &__title {
    margin-bottom: scale-to-em(16px, $h3-size);

    color: $color-font;

    &:not(:first-child) {
      margin-top: scale-to-em(8px, $h3-size);
    }
  }

  &__date {
    display: block;

    font-size: scale-to-em($font-size-xs, $font-size);

    color: $color-meta;
  }

  &__link {
    text-decoration: none;

    transition: color $animation-duration $ease-out-cubic;

    &::before {
      @include fit;
      content: '';
    }

    &:hover,
    &:focus {
      outline: none;

      color: $color-primary;
    }
  }
}
