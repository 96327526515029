@import 'styles/config';

.listGrid {
  --cols: 1;

  align-items: flex-start;

  @media (min-width: $min-tablet) {
    --cols: 2;
  }

  &.three {
    @media (min-width: $min-desktop) {
      --cols: 3;
    }
  }
  &.four {
    @media (min-width: $min-desktop) {
      --cols: 4;
    }
  }
}
